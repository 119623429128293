<template>
  <header class="text-center">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <div class="nav-flex">
          <div
            class="text-left d-flex"
            style="color: black"
          >
            <div class="menu">
              <div @click="openNav()">
                <div class="mr-4">
                  <div class="bar1"></div>
                  <div class="bar2"></div>
                  <div class="bar3"></div>
                </div>
              </div>
            </div>
            <div>
              <span style="font-weight: 600">
                {{ $store.state.fullName }} 
              </span><br />
              <small
                class="text-success"
                v-if="$store.state.userRoles == 'ADMIN'"
              >Admin
              </small>
              <small
                class="text-success"
                v-else
              >Support</small>
            </div>
          </div>

          <div class="
              nav-list-two
              d-flex
              align-items-center
              justify-content-center
            ">
            <div class="theProfile">
              <div style="width: 40px; height: 40px; border-radius: 50%">
                <div style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #233A2E;
                    color: white;
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: 100%;
                  ">
                  <img
                    style="border-radius:100%; width:40px; height: 40px;"
                    :src="$store.state.profilePicture"
                  >
                  <!-- <strong>{{
                    getInitials($store.state.FullName)
                  }}</strong> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <el-drawer
      title="I am the title"
      :visible.sync="sidebarDrawer"
      direction="ltr"
      size="70%"
      :with-header="false"
    >
      <div class="sidebar">
        <div style="cursor: pointer">
          <div style="
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
            <img
              :src="$store.state.logoImageUrl"
              style="height: 8em; border-radius: 10px;"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
        <div class="side-link">
          <ul>
            <li @click="closeNav">
                <router-link to="/">
                  <div
                    class="collapsable-side-bar-item"
                    :class="{ active: $route.name == 'Home' }"
                  >
                    <div class="text-truncate">
                      <i
                        style="padding-right:5px"
                        class="el-icon-menu"
                      ></i> Dashboard
                    </div>
                  </div>
                </router-link>
            </li>
            
            <li @click="closeNav">
                <router-link to="/categories">
                  <div
                    class="collapsable-side-bar-item"
                    :class="{ 
                      active: $route.name == 'Categories' ||
                      $route.name == 'Products' ||
                      $route.name == 'AddProduct' ||
                      $route.name == 'EditProduct'||
                      $route.name == 'Colors' ||
                      $route.name == 'AddColor' ||
                      $route.name == 'EditColor', 
                    }"
                  >
                    <div class="text-truncate">
                      <i
                        style="padding-right:5px"
                        class="el-icon-s-grid"
                      ></i> Categories
                    </div>
                  </div>
                </router-link>
            </li>
            
            <li @click="closeNav">
                <router-link to="/product-groups">
                  <div
                    class="collapsable-side-bar-item"
                    :class="[$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: $route.name == 'ProductGroups' }]"
                  >
                    <div class="text-truncate">
                      <i
                        style="padding-right:5px"
                        class="el-icon-share"
                      ></i> Product Groups
                    </div>
                  </div>
                </router-link>
              </li>
            
            <li @click="closeNav">
                <router-link to="/banners">
                  <div
                    class="collapsable-side-bar-item"
                    :class="[$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: $route.name == 'Banners' }]"
                  >
                    <div class="text-truncate">
                      <i
                        style="padding-right:5px"
                        class="el-icon-s-shop"
                      ></i> Banners
                    </div>
                  </div>
                </router-link>
            </li>
            
            <li @click="closeNav">
                <router-link to="/countries">
                  <div
                    class="collapsable-side-bar-item"
                    :class="{ active: $route.name == 'Countries' }"
                  >
                    <div class="text-truncate">
                      <i
                        style="padding-right:5px"
                        class="el-icon-s-shop"
                      ></i> Countries
                    </div>
                  </div>
                </router-link>
            </li>
            
            <li @click="closeNav">
                <router-link to="/pickup-locations">
                  <div
                    class="collapsable-side-bar-item"
                    :class="{ active: $route.name == 'PickupLocations' }"
                  >
                    <div class="text-truncate">
                      <i
                        style="padding-right:5px"
                        class="el-icon-s-flag"
                      ></i> Pick-Up Locations
                    </div>
                  </div>
                </router-link>
              </li>
                        
            <li @click="closeNav">
                <router-link to="/orders">
                  <div
                    class="collapsable-side-bar-item"
                    :class="{ active: $route.name == 'Orders' }"
                  >
                    <div class="text-truncate">
                      <i
                            style="padding-right:5px"
                            class="el-icon-s-data"
                          ></i> Orders
                    </div>
                  </div>
                </router-link>
              </li>


            <li
              @click="$router.push({path: '/safari-packages'})"
              :class="{ active: $route.name == 'SafariPackages' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-user"
              ></i> Clients
            </li>
            
            <li @click="closeNav">
                <router-link to="/users">
                  <div
                    class="collapsable-side-bar-item"
                    :class="[$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: $route.name == 'Users' }]"
                  >
                    <div class="text-truncate">
                      <i
                        style="padding-right:5px"
                        class="el-icon-user-solid"
                      ></i> Team
                    </div>
                  </div>
                </router-link>
            </li>
              
            <li
              @click="$router.push({path: '/safari-packages'})"
              :class="[$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: $route.name == 'SafariPackages' }]"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-operation"
              ></i> Transactions
            </li>
              
              <li
                @click="$router.push({path: '/newsLetters'})"
                :class="{ active: $route.name == 'NewsLetters' }"
              >
                <i
                  style="padding-right:5px"
                  class="el-icon-s-operation"
                ></i> NewsLetters
              </li>

            <li @click="logout">
              <i
                style="padding-right:5px"
                class="fas fa-arrow-left"
              ></i> Logout
            </li>
          </ul>
        </div>
      </div>
    </el-drawer>
  </header>
</template>

<script>
export default {
  data() {
    return {
      sidebarDrawer: false,
    };
  },

  computed: {
    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },
  },

  mounted() {
    // this.setCurrentUser();
  },

  methods: {
    openNav() {
      this.sidebarDrawer = true;
    },

    closeNav() {
      this.sidebarDrawer = false;
    },

    logout() {
      // remove user from local storage to log user out profilePicture
      localStorage.removeItem("user");
      this.sidebarDrawer = false;
      location.reload(true);
    },
  },
};
</script>

<style scoped>
nav {
  background-color: #f5f7fa;
  color: #fbf2e1;
  padding-top: 10px;
  padding-bottom: 10px;
}
nav a {
  color: #fbf2e1;
  text-decoration: none;
}
a .active {
  border-bottom: 1px solid grey;
  color: grey;
  font-weight: 600;
}

a:hover span {
  border-bottom: 1px solid grey;
  transition: 0.5s;
}

.side-link {
  color: var(--el-app-primary);
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-flex .nav-list-two {
  list-style: none;
  height: 100%;
  padding: 0px;
}

.nav-flex span {
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex span:hover {
  color: #d96b12;
  transition: 0.5s;
}

.menu {
  display: none;
}

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 2px;
  background-color: black;
  margin: 5px 0;
  border-radius: 3px;
  transition: 0.4s;
}

.sidebar {
  background-color: #F5C4C3; 
  height: 100%;
}

.sidebar ul {
  list-style: none;
  padding: 0px;
  padding-bottom: 50px;
  margin: 0px;
  text-align: left;
}

.sidebar ul li {
  border-bottom: 1px solid #F5C4C3;
  /* border-bottom: 1px solid #F5C4C3; */
  padding-top: 30px;
  padding-left: 30px;
}

.sidebar ul li a {
  color: black;
}

.sidebar ul li a:hover {
  color: #fdfdfd;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .menu {
    display: block;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .menu {
    display: block;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .menu {
    display: block;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .menu {
    display: block;
  }
}
</style>

<style>
.el-drawer {
  background-color: #F5C4C3; ;
}
.v-modal {
  z-index: 0 !important;
}
</style>