import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '../views/admin-dashboard/dashboard.vue'
import WebsiteDashboard from '../views/website/dashboard.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/ovalspace/os-admin',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: '/ovalspace/os-admin',
        name: 'BackOfficeHome',
        component: () => import(/* webpackChunkName: "home" */ '../views/admin-dashboard/Home.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/categories',
        name: 'Categories',
        component: () => import(/* webpackChunkName: "categories" */ '../views/admin-dashboard/categories.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/rooms/:categoryID',
        name: 'Rooms',
        component: () => import(/* webpackChunkName: "rooms" */ '../views/admin-dashboard/rooms.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/category/:category_id/:category_name/products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "products" */ '../views/admin-dashboard/products.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/category/add-product',
        name: 'AddProduct',
        component: () => import(/* webpackChunkName: "add-product" */ '../views/admin-dashboard/add-product.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/category/edit-product/:categoryID',
        name: 'EditProduct',
        component: () => import(/* webpackChunkName: "edit-product" */ '../views/admin-dashboard/edit-product.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/banners',
        name: 'Banners',
        component: () => import(/* webpackChunkName: "banners" */ '../views/admin-dashboard/banners.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../views/admin-dashboard/Users.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/reservations',
        name: 'BackOfficeReservations',
        component: () => import(/* webpackChunkName: "back-office-reservations" */ '../views/admin-dashboard/reservations.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/bookings',
        name: 'BackOfficeBookings',
        component: () => import(/* webpackChunkName: "back-office-bookings" */ '../views/admin-dashboard/bookings.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/contact-us-messages',
        name: 'ContactUsMessages',
        component: () => import(/* webpackChunkName: "contact-us-messages" */ '../views/admin-dashboard/contact-us-messages.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/guest-feedbacks',
        name: 'GuestFeedBacks',
        component: () => import(/* webpackChunkName: "guest-feedbacks" */ '../views/admin-dashboard/guest-reviews.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/blog',
        name: 'BackOfficeBlog',
        component: () => import(/* webpackChunkName: "BackOffice-blog" */ '../views/admin-dashboard/blog.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ovalspace/os-admin/newsletters',
        name: 'Newsletters',
        component: () => import(/* webpackChunkName: "newsletters" */ '../views/admin-dashboard/newsletters.vue'),
        meta: { requiresLogin: true }
      },

    ]
  },
  {
    path: '/ovalspace/os-admin/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/admin-dashboard/login.vue')
  },

  {
    path: '/',
    name: 'WebsiteDashboard',
    component: WebsiteDashboard,

    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Home.vue')
      },
      {
        path: '/about-us',
        name: 'AboutUs',
        component: () => import(/* webpackChunkName: "about-us" */ '../views/website/About.vue')
      },
      {
        path: '/dining',
        name: 'Dining',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Dining.vue')
      },
      {
        path: '/accommodation',
        name: 'Accommodation',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Accommodation.vue')
      },
      {
        path: '/accommodation-detail/:categoryID',
        name: 'AccommodationDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Accommodation-detail.vue')
      },
      {
        path: '/reservations',
        name: 'Reservations',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Reservations.vue')
      },
      {
        path: '/reservation-submitted',
        name: 'Payment',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Payment.vue')
      },
      {
        path: '/contact-us',
        name: 'ContactUs',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Contact-us.vue')
      },
      {
        path: '/guest-reviews/:roomID/:roomNumber',
        name: 'GuestReviews',
        // route level code-splitting
        // this generates a separate chunk guest-reviews.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "guest-reviews" */ '../views/website/guest-reviews.vue')
      },
      {
        path: '/meetings-and-events',
        name: 'MeetingsAndEvents',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Meetings-and-events.vue')
      },
      {
        path: '/gallery',
        name: 'Gallery',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Gallery.vue')
      },
      {
        path: '/our-team',
        name: 'OurPeople',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "our-team" */ '../views/website/our-people.vue')
      },
      {
        path: '/blog',
        name: 'Blog',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "our-team" */ '../views/website/blog.vue')
      },
      {
        path: '/blog/:blogID',
        name: 'Blog',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "our-team" */ '../views/website/blog-post.vue')
      }

    ]
  },

  //In case the route is anything else
  {
    path: '*',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  if (to.name === "Login" && localStorage.getItem('user')) {
    next({ path: "/ovalspace/os-admin" });
  }

  //Check if login is required for Routes with meta data loginRequired!
  if (to.matched.some(record => record.meta.requiresLogin)) {
    if (!localStorage.getItem('user')) {
      //Redirect to Login Page
      next({ path: "/ovalspace/os-admin/login" });
    } else next();
  } else next();
});

export default router
